import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";

const AiSubtasks = ({refetchData, column, parent_task_id, closeModal, projectAuthorizations, project_id, users, setArray}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const {setColumns} = useContext(TaskboardContext);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState("Your job is to take the description and question & answers array for a request submitted by a client and turn it into subtasks. Each subtask will have a short title based on the description you decide it should have. Please refrain from creating too many small subtasks, as the subtasks will be assigned to certain teammates and they do not want to see any more than 5 subtasks (feel free to put more than one instruction for subtask description so we don't miss any requirements). The description from the request may not all be instructions, so please use your best judgement to create a concise and clear subtasks based on the overall requests description and answers to the questions if there is any. Please include any relevent details for the subtask description if needed that may help the assigned teammate complete the subtask. Please also determine the priority of the subtask based on the language sense of urgency used in the description for that subtask (your options are High, Medium, and Low). Order the subtasks based on the descriptions context on what makes the most sense to finish in order to complete the entire request.");

  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/openai_subtask_generator`, {
      column_id: column.token,
      parent_task_id: parent_task_id, 
      organization_id: match.params.organization_id,
      prompt: prompt, 
      project_id: project_id || match.params.project_id, 
      parent_task_id: parent_task_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div class="btn-group" data-tip="This is experimental. Give us feedback using Intercom chat widget so we can improve it!">
        <a disabled={disabled} className="btn btn-small border-all background-hover color-1" onClick={handleSubmit}>
          {disabled ? <><i className="fas fa-spinner fa-spin"></i> Generating...</> : "✨ AI subtasks ✨"}
        </a>
        <a type="button" class="btn btn-small border-all background-hover color-1 dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
          <span class="sr-only">Toggle Dropdown</span>
        </a>

        <ul class="dropdown-menu animated fadeInUp" role="menu">
          <li><a onClick={() => setOpen(true)}><i className="fa fa-pencil"></i>Modify prompt</a></li>
        </ul>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Use AI to generate subtasks</h4>

        <div className="row no-margin">
          <div className="field">
            <textarea rows="10" className="form-control" value={prompt} onChange={(e) => setPrompt(e.target.value)}></textarea>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AiSubtasks
import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import EmojiPicker from 'emoji-picker-react';
import {ProjectContext} from "../Projects/ProjectContext.js";

const NewGroup = ({refetchData, onCloseModal, group}) => {
  const {setGroups} = useContext(ProjectContext);
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [title, setTitle] = useState(group?.title || "");
  const [icon, setIcon] = useState(group?.emoji || "");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/groups`, {
      title: title, 
      emoji: icon
    })
    .then(function(response){
      if(response.data.success){
        setGroups(prevState => [...prevState, response.data.group]);
        refetchData();  
        onCloseModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/groups/${group.token}`, {
      title: title, 
      emoji: icon
    })
    .then(function(response){
      if(response.data.success){
        setGroups(prevState => prevState.map(g => 
          g.token === response.data.group.token 
            ? { ...g, title: response.data.group.title, emoji: response.data.group.emoji }
            : g
        ));
        refetchData();
        onCloseModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleEmojiClick = (emojiObject) => {
    setIcon(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return(
    <React.Fragment>
      <div className="row">
        <form onSubmit={group ? handleUpdate : handleSubmit}>
          <div className="field">
            <label>Icon</label>
            <div className="group-icon-selector background-3 border-all" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
              {icon || <i className="fal fa-plus"></i>}
            </div>
            {showEmojiPicker && (
              <div style={{ position: 'absolute', zIndex: 1 }}>
                <EmojiPicker onEmojiClick={handleEmojiClick} lazyLoadEmojis={true}/>
              </div>
            )}
          </div>
          <div className="field">
            <label>Title</label>
            <input className="form-control" type="text" name="title" onChange={(e) => setTitle(e.target.value)} defaultValue={group?.title}/>
          </div>

          <div className="field text-right">
            <button disabled={disabled || !title || !icon} type="submit" className="btn btn-primary">{t("Submit")}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default NewGroup;